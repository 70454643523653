'use client';

import {
	ApolloClient,
	ApolloProvider as _ApolloProvider,
	InMemoryCache,
	from,
	defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAppSyncHttpLink, createAppSyncHybridLink, prewarmToken } from '@packages/appsync-apollo-links';
import { OperationTypeNode } from 'graphql';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { debug } from '@/lib/debug';
import { oxidAccessTokenAtom, cognitoAccessTokenAtom } from '@/state/state';
import { isGraphQLSubscriptionEstablishedAtom } from '@/state/state-system';
import { globalStore } from '@/state/store';

import '@packages/appsync/utils/polyfill';

const d = debug.extend('ApolloProvider');

const clientUri = process.env.NEXT_PUBLIC_GRAPHQL_URI ?? `https://graphql-ecom.oxid.one`;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (networkError) {
		console.log(`Network error`, networkError, graphQLErrors, operation);

		for (const queryDefinition of operation.query.definitions) {
			if ('operation' in queryDefinition && queryDefinition.operation === OperationTypeNode.SUBSCRIPTION) {
				console.error('🔴 CRITICAL SUBSCRITPION ERROR');
			}
		}

		if ('errors' in networkError) {
			const errors = networkError.errors as { message: string }[];

			for (const error of errors) {
				if (error.message === 'Timeout disconnect') {
					console.error(
						'Websockets disconnected. THIS SHOULD BE HANDLED!!! but we do not yet know how. Maybe go back to plain apollo client and links...',
					);
				}
			}
		}

		const context = operation.getContext() as { response?: Response };

		if (context?.response?.status === 401) {
			console.log('401 error ....');
			console.warn(
				'LOGOUT USER ! OR RESTART AUTH FLOW ! we had a logout here, but since the state lives inside react, we need another solution',
			);
			// window.location.href = '/auth/login';
		}
	}

	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			d('GraphQL error', { message, locations, path });
		});
	}
});

// https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support

const url = `${clientUri}/graphql`;

// export const token = {
// 	get value() {
// 		return getToken();
// 	},
// };

export function getToken() {
	const cognitoAccessToken = globalStore.get(cognitoAccessTokenAtom);
	const oxidAccessToken = globalStore.get(oxidAccessTokenAtom);

	return oxidAccessToken ? `Bearer ${oxidAccessToken}` : cognitoAccessToken ?? '';
}

const linkWithoutSubscription = from([errorLink, createAppSyncHttpLink({ appSyncApiUrl: url, getJwtToken: getToken })]);

export const apolloClient = new ApolloClient({
	link: linkWithoutSubscription,
	cache: new InMemoryCache({
		dataIdFromObject(responseObject) {
			if (responseObject && 'id' in responseObject && typeof responseObject['id'] === 'string') {
				try {
					const id = atob(responseObject?.id);
					return id;
				} catch (err) {
					console.error('error', err);
				}
			}
			return defaultDataIdFromObject(responseObject);
		},
		addTypename: true,
	}),
	name: 'OxidEcom',
	version: '0.0.1',
	connectToDevTools: true,
});

export const GraphQLProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const oxidToken = useAtomValue(oxidAccessTokenAtom);
	const setIsRealtimeConnectionActive = useSetAtom(isGraphQLSubscriptionEstablishedAtom);

	useEffect(() => {
		if (oxidToken) {
			// this is a little hacky but it works fine
			prewarmToken(`Bearer ${oxidToken}`);
			apolloClient.setLink(
				from([
					errorLink,
					createAppSyncHybridLink({ appSyncApiUrl: url, getJwtToken: getToken }, (error) => {
						if (error) {
							setIsRealtimeConnectionActive(false);
						} else {
							setIsRealtimeConnectionActive(true);
						}
					}),
				]),
			);
			// we need to set this here now to allow realtime components to mount and initiate the realtime connection
			setIsRealtimeConnectionActive(true);
		} else {
			apolloClient.setLink(linkWithoutSubscription);
			setIsRealtimeConnectionActive(false);
		}
	}, [oxidToken, setIsRealtimeConnectionActive]);

	return <_ApolloProvider client={apolloClient}>{children}</_ApolloProvider>;
};
